/* eslint-disable */
// responsive tables
(function() {
  const tablesList = document.querySelectorAll('.content table');
  const tables = Array.prototype.slice.call(tablesList, 0);

  tables.forEach((table) => {
    const wrapper = document.createElement('div');
    wrapper.classList.add('table-wrapper');
    wrapper.innerHTML = table.outerHTML;
    table.parentNode.insertBefore(wrapper, table);
    table.remove();
  });
})();
