import '@babel/polyfill';
import bulmaAccordion from 'bulma-accordion';
import SmoothScroll from 'smooth-scroll';

const Symbol = require('es6-symbol');

Symbol();

document.addEventListener('DOMContentLoaded', () => {
  if (typeof Symbol !== 'undefined') {
    bulmaAccordion.attach();
  }

  const $accordions = Array.prototype.slice.call(
    document.querySelectorAll('.accordion'),
    0,
  );

  if ($accordions.length >= 1) {
    $accordions.forEach((element) => {
      element.addEventListener('click', function accordionTimeout() {
        const ele = this;
        setTimeout(() => {
          const scroll = new SmoothScroll();
          scroll.animateScroll(ele, null, { offset: 120 });
        }, 200);
      });
    });
  }
});
