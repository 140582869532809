document.addEventListener('DOMContentLoaded', () => {
  // Get all 'navbar-burger' elements
  const $navbarBurgers = Array.prototype.slice.call(
    document.querySelectorAll('.navbar-burger'),
    0,
  );

  // Get all dropdown items
  const $dropdowns = Array.prototype.slice.call(
    document.querySelectorAll('.has-dropdown'),
    0,
  );

  // init openDropdowns
  let $openDropdowns = {};

  // function to check for open dropdowns
  const updateOpenDropdowns = () => {
    $openDropdowns = Array.prototype.slice.call(
      document.querySelectorAll('.has-dropdown.is-active'),
      0,
    );
  };

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach((el) => {
      el.addEventListener('click', () => {
        // Get the target from the 'data-target' attribute
        const { target } = el.dataset;
        const $target = document.getElementById(target);

        // Toggle the 'is-active' class on both the 'navbar-burger' and the 'navbar-menu'
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
        document.querySelector('.cfGMLl').classList.toggle('is-nav-active');
      });
    });
  }

  if ($dropdowns.length > 0) {
    // Add a click event on each of them
    $dropdowns.forEach((el) => {
      el.addEventListener('click', () => {
        updateOpenDropdowns();
        el.classList.toggle('is-active');
      });
    });
  }

  document.addEventListener('click', (e) => {
    if ($openDropdowns.length > 0) {
      const isClickInside = $openDropdowns[0].contains(e.target);
      if (!isClickInside) {
        $openDropdowns[0].classList.remove('is-active');
      }
    }
    updateOpenDropdowns();
  });

  // nav accessibity - manage navi with tab
  (function() {
    document.addEventListener('keydown', (event) => {
      setTimeout(() => {
        let key;
        let activeEl;
        key = window.event ? event.keyCode : event.which;
        // Check Tab
        if (key === 9) {
          activeEl = document.activeElement;
          if (activeEl.className === 'navbar-link') {
            activeEl.click();
          } else if (activeEl.className === 'nav-link-conatiner nav-no-drop') {
            Array.prototype.slice
              .call(document.querySelectorAll('.has-dropdown.is-active'), 0)[0]
              .classList.remove('is-active');
          }
          activeEl = null;
        }
        if (key === 13) {
          activeEl = document.activeElement;
          if (
            activeEl.className === 'nav-link-conatiner' ||
            activeEl.className === 'nav-link-conatiner subnavi' ||
            activeEl.className === 'nav-link-conatiner nav-no-drop' ||
            activeEl.className === 'click-lang-switch'
          ) {
            activeEl.getElementsByTagName('a')[0].click();
          }
          if (activeEl.id === 'start-search') {
            document.querySelector('#search').classList.toggle('visible');
            document.querySelector('#search input').focus();
          }
          activeEl = null;
        }
      }, 200);
    });
  })();
});
