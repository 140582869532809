import $ from 'jquery';
$(document).ready(() => {
  // add target noopener noreferrer to external links
  function add_target_blank_to_external_links() {
    $('body a[href^="http"]')
      .not(`a[href*="${location.hostname}"]`)
      .attr({
        target: '_blank',
        rel: 'noopener noreferrer',
      });
  }
  add_target_blank_to_external_links();
});
