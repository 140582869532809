import $ from 'jquery';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

$(document).ready(() => {
  // scroll to next section
  $('.scroll-to-next-section').click(function() {
    $('html, body').animate(
      {
        scrollTop: $('main').offset().top - 80,
      },
      1500,
    );
  });

  $('#navbar').on('scroll', function() {
    if ($('#1-navbar-item').offset().top < 120) {
      $('#1-navbar-item').css(
        'opacity',
        1 - 60 / Math.abs($('#1-navbar-item').offset().top),
      );
    } else {
      $('#1-navbar-item').css('opacity', 1);
    }
    if ($('#2-navbar-item').offset().top < 120) {
      $('#2-navbar-item').css(
        'opacity',
        1 - 20 / Math.abs($('#2-navbar-item').offset().top),
      );
    } else {
      $('#2-navbar-item').css('opacity', 1);
    }
  });
  gsap.from('.trigger-animation', {
    y: -50,
    scrollTrigger: {
      trigger: 'header',
      start: 'top top',
      pin: false,
      scrub: 0.1,
    },
  });
  gsap.from('.trigger-animation-video', {
    y: 80,
    scrollTrigger: {
      trigger: 'header',
      start: 'top top',
      pin: false,
      scrub: 0.1,
    },
  });

  $(".colNumberValue").each(function(index, element) {
    var count = $(this),
        zero = {val:0},
        num = String(count.data("number")),
        isEuropean = false;

    if(num.includes(',')){
      isEuropean = true
      num = String(count.data("number")).replace(',','.')
    }

    var split = (num + "").split(".");
    var decimals = split.length > 1 ? split[1].length : 0;

    gsap.to(zero, {
      val: num,
      duration: 2,
      scrollTrigger: element,
      onUpdate: function() {
        console.log(isEuropean)
        if(isEuropean){
          count.text(String(zero.val.toFixed(decimals)).replace('.',','));
        }else{
          count.text(String(zero.val.toFixed(decimals)));
        }
      }
    });
  });
});
