/* eslint-disable space-before-function-paren */
/* eslint-disable func-names */
const $ = require('jquery');

$.fn.newsFilter = function() {
  const NewsSection = $(this);

  const langKeywords = {
    addFilter: {
      en: 'Add filter',
      de: 'Filter hinzufügen',
      fr: 'Ajouter des filtres de recherche',
    },
    editFilter: {
      en: 'Edit filter',
      de: 'Filter bearbeiten',
      fr: 'Modifier les filtres',
    },
    filterText: {
      en: 'Delete filter',
      de: 'Filter löschen',
      fr: 'Supprimer les filtres',
    },
  };

  class CustFilter {
    constructor() {
      if (window.innerWidth > 760) {
        this.mode = 'tab';
      } else {
        this.mode = 'mobile';
      }
      this.lang = this.getLang();
      this.filter = {};
      this.isFilterApplied = false;
      this.setFilterControls();
      this.setInitialFilters();
      this.setHandlers();
    }

    setInitialFilters() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      // eslint-disable-next-line func-names
      NewsSection.find('.filterOption').each(function() {
        const obj = {};
        const filterName = $(this).attr('o-name');
        const allInputs = $(this).find('input');
        const checkboxOptionsMeta = {};

        allInputs.each((_index, input) => {
          $(input)[0].checked = false;
          const dataVal = $(input).attr('data-value');
          obj[dataVal] = true;
          checkboxOptionsMeta[dataVal] = $(input)
            .parents('.checkbox')
            .find('.optionTitle')
            .text();
        });
        obj.optionText = {};
        obj.optionText.realText = $(this)
          .find('.optionText')
          .text();
        obj.optionText.checkboxOptionsMeta = checkboxOptionsMeta;
        _this.filter[filterName] = obj;
      });
    }

    filterNews(checkMode) {
      // eslint-disable-next-line no-unused-vars
      $('.use-without-filters').hide();
      let shouldApply = false;
      if (checkMode) {
        if (this.mode === 'tab') {
          shouldApply = true;
        }
      } else {
        shouldApply = true;
      }
      if (!shouldApply) {
        return;
      }
      const validator = {};
      // eslint-disable-next-line no-underscore-dangle
      const _filter = this.filter;
      $.each(this.filter, (index, value) => {
        validator[index] = Object.keys(value).filter(
          (x) => _filter[index][x] === true,
        );
      });
      // console.log(validator,"VALIDATOR");
      const filter = {};
      // eslint-disable-next-line func-names
      NewsSection.find('.gi-news-box').each(function(index) {
        const thisSection = $(this);
        filter[index] = {};
        filter[index].section = thisSection;
        filter[index].values = [];
        // eslint-disable-next-line consistent-return
        $.each(validator, (optionName, validOptions) => {
          const thisNewsOptions = thisSection
            .attr(`data-${optionName}`)
            .split(' ');
          let has = false;
          validOptions.forEach((op) => {
            if (thisNewsOptions.includes(op)) {
              has = true;
            }
          });
          // if filter options are not added
          if (thisNewsOptions[0] === '') {
            has = true;
          }
          // Adding the wierd hack here :(
          if (!validOptions.length) {
            has = true;
          }
          filter[index].values.push(has);
        });
      });
      this.applyFilter(filter);
    }

    // eslint-disable-next-line class-methods-use-this
    applyFilter(filter) {
      $.each(filter, (_index, value) => {
        const isNotValidated = value.values.find((x) => x === false);
        if (isNotValidated === false) {
          value.section.css('display', 'none');
        } else {
          value.section.css('display', 'block');
        }
      });
    }

    // x
    setHandlers() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      NewsSection.find('.filterOption').each(function(_i, thisFilterOption) {
        const obj = {};
        const filterName = $(this).attr('o-name');
        const allInputs = $(this).find('input');
        const thisOptionText = _this.filter[filterName].optionText.realText;
        const { checkboxOptionsMeta } = _this.filter[filterName].optionText;
        obj.optionText = {};
        obj.optionText.realText = thisOptionText;
        obj.optionText.checkboxOptionsMeta = checkboxOptionsMeta;
        allInputs.each((_index, input) => {
          $(input).click(() => {
            // eslint-disable-next-line no-shadow
            allInputs.each((_index, input) => {
              const isChecked = $(input)[0].checked;
              const dataVal = $(input).attr('data-value');
              obj[dataVal] = isChecked;
            });
            _this.filter[filterName] = obj;
            _this.filterNews(true);
            _this.checkFilter(thisFilterOption);
            _this.optionClicked = thisFilterOption;
            _this.showSelectedOptions();
          });
        });
      });

      NewsSection.find('.removeFilter').click(function() {
        // eslint-disable-next-line prefer-arrow-callback
        $.each(_this.filter, function(_index, thisFilterValues) {
          // eslint-disable-next-line space-in-parens
          $.each(thisFilterValues, (thisFilterName) => {
            // eslint-disable-next-line no-underscore-dangle
            if (thisFilterName !== 'optionText') {
              // eslint-disable-next-line no-underscore-dangle
              _this.filter[_index][thisFilterName] = true;
            }
          });
        });
        _this.filterNews(false);
        _this.showSelectedOptions();
        NewsSection.find('input[type="checkbox"]').each(function() {
          $(this)[0].checked = false;
        });
        $(this)
          .addClass('filterInactive')
          .removeClass('filterActive');
        _this.isFilterApplied = false;
        _this.changeFilterToggleText();
      });

      NewsSection.find('.applyFilter').click(function(e) {
        e.preventDefault();
        _this.filterNews(false);
        $(this)
          .parents('.gi-news-filter')
          .css('display', 'none');
        _this.changeFilterToggleText();
      });
    }

    // eslint-disable-next-line class-methods-use-this
    showSelectedOptions() {
      $.each(this.filter, (optionName, values) => {
        let selectedOptionsArray = [];
        $.each(values, (i, v) => {
          if (v) {
            selectedOptionsArray.push(i);
          }
        });
        if (selectedOptionsArray.length === Object.values(values).length) {
          $(`div[o-name='${optionName}']`)
            .find('.optionText')
            .text(values.optionText.realText);
        } else {
          selectedOptionsArray = selectedOptionsArray.filter(
            (x) => x !== 'optionText',
          );
          const optionsMetaObject = this.filter[optionName].optionText
            .checkboxOptionsMeta;
          // eslint-disable-next-line max-len
          let toShowValues = selectedOptionsArray.map(
            (thisOption) => optionsMetaObject[thisOption],
          );
          toShowValues = toShowValues.join(',');
          $(`div[o-name='${optionName}']`)
            .find('.optionText')
            .text(toShowValues);
        }
      });
    }

    checkFilter(filterOption) {
      const filterName = $(filterOption).attr('o-name');
      const areAllOptionsUnchecked = Object.values(
        this.filter[filterName],
      ).find((thisVal) => thisVal === true);
      if (!areAllOptionsUnchecked) {
        // eslint-disable-next-line array-callback-return
        Object.keys(this.filter[filterName]).map((thisFilterOption) => {
          if (thisFilterOption !== 'optionText') {
            this.filter[filterName][thisFilterOption] = true;
          }
        });
      }
      let isFilterApplied = false;
      // eslint-disable-next-line prefer-arrow-callback
      $.each(this.filter, function(_index, thisFilterValues) {
        // eslint-disable-next-line consistent-return
        Object.values(thisFilterValues).forEach((thisVal) => {
          if (thisVal === false) {
            isFilterApplied = true;
            return false;
          }
          // eslint-disable-next-line indent
        });
      });
      if (isFilterApplied) {
        NewsSection.find('.removeFilter')
          .addClass('filterActive')
          .removeClass('filterInactive');
      } else {
        NewsSection.find('.removeFilter')
          .addClass('filterInactive')
          .removeClass('filterActive');
      }
      this.isFilterApplied = isFilterApplied;
    }

    // eslint-disable-next-line class-methods-use-this
    setFilterControls() {
      if (window.innerWidth > 760) {
        const keywordText = this.getKeywordText('filterText');
        NewsSection.find('.gi-news-filter').after(
          `<div class="container"><a class="removeFilter filterInactive tabFilterDisableBtn">${keywordText}</a></div>`,
        );
      }
    }

    // Helpers
    changeFilterToggleText() {
      if (this.isFilterApplied) {
        const keywordText = this.getKeywordText('editFilter');
        NewsSection.find('.filterAdd').text(keywordText);
      } else {
        const keywordText = this.getKeywordText('addFilter');
        NewsSection.find('.filterAdd').text(keywordText);
      }
    }

    // eslint-disable-next-line class-methods-use-this
    getLang() {
      return document.getElementsByTagName('html')[0].getAttribute('lang');
    }

    // eslint-disable-next-line class-methods-use-this
    getKeywordText(keyword) {
      const key = Object.keys(langKeywords).find((x) => x === keyword);
      return langKeywords[key][this.lang];
    }
  }

  new CustFilter();
};
