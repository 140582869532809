/* eslint-disable */

import $ from 'jquery';

$('.relatedNewsPaginate').click(function(e) {
  e.preventDefault();
  var allNews = $(this)
    .parents('.country-news')
    .find('.gi-news-box');
  var newsBoxes = $.makeArray(allNews);
  let index = newsBoxes.findIndex((ele) => {
    return $(ele).css('display') == 'none';
  });
  var toShowNews = index + 3;
  allNews.each((index, ele) => {
    if (index < toShowNews) {
      $(ele).css('display', 'block');
    }
  });
  if (toShowNews >= allNews.length) {
    $(this).css('display', 'none');
  }
});

$('.relatedProjectsPaginate').click(function(e) {
  e.preventDefault();
  var allNews = $(this)
    .parents('.country-project')
    .find('.gi-news-box');
  var newsBoxes = $.makeArray(allNews);
  let index = newsBoxes.findIndex((ele) => {
    return $(ele).css('display') == 'none';
  });
  var toShowNews = index + 3;
  allNews.each((index, ele) => {
    if (index < toShowNews) {
      $(ele).css('display', 'block');
    }
  });
  if (toShowNews >= allNews.length) {
    $(this).css('display', 'none');
  }
});

$('.storiesPaginate').click(function(e) {
  e.preventDefault();
  var allNews = $(this)
    .parents('.gi-stories')
    .find('.gi-news-box');
  var newsBoxes = $.makeArray(allNews);
  let index = newsBoxes.findIndex((ele) => {
    return $(ele).css('display') == 'none';
  });
  var toShowNews = index + 3;
  allNews.each((index, ele) => {
    if (index < toShowNews) {
      $(ele).css('display', 'block');
    }
  });
  if (toShowNews >= allNews.length) {
    $(this).css('display', 'none');
  }
});
