import objectFitImages from 'object-fit-images';
import LazyLoad from 'vanilla-lazyload';

document.addEventListener('DOMContentLoaded', () => {
  objectFitImages();

  new LazyLoad({
    elements_selector: 'img',
  });
});
