import SmoothScroll from 'smooth-scroll';
const navbarmainheight = document.getElementById('navsearchwrapper')
  .offsetHeight;
let inpagenavheight = 0;
if (document.getElementById('in-page-nav-container') != null) {
  inpagenavheight = document.getElementById('in-page-nav-container')
    .offsetHeight;
}

new SmoothScroll('a[href*="#"]:not(#scrollBar)', {
  offset: navbarmainheight + inpagenavheight,
});
