import $ from 'jquery';
$(document).ready(() => {
  // instantiate all news filters
  $('.customNewsFilter').newsFilter();
  $('.customNewsFilter2').newsFilter();
  // eslint-disable-next-line func-names
  // eslint-disable-next-line space-before-function-paren
  // eslint-disable-next-line func-names

  // eslint-disable-next-line func-names

  if (window.innerWidth > 760) {
    // eslint-disable-next-line func-names
    $('.customSelect').hover(function() {
      $(this)
        .find('.customSelectOptions')
        .removeClass('hidden');
    });
    // eslint-disable-next-line func-names
    $('.customSelect').mouseleave(function() {
      $(this)
        .find('.customSelectOptions')
        .addClass('hidden');
    });
  } else {
    // eslint-disable-next-line no-alert
    // eslint-disable-next-line func-names
    $('.customSelect').click(function() {
      $(this)
        .find('.customSelectOptions')
        .toggleClass('hidden');
      const arrow = $(this).find('.Chevron');
      if (arrow.hasClass('filter-arrow-down')) {
        arrow.removeClass('filter-arrow-down').addClass('filter-arrow-up');
        arrow[0].style.transform = 'rotate(180deg)';
      } else {
        arrow.removeClass('filter-arrow-up').addClass('filter-arrow-down');
        arrow[0].style.transform = 'rotate(360deg)';
      }
    });
  }

  // eslint-disable-next-line func-names
  $('.filterAdd').click(function() {
    $(this)
      .parents('.filterSection')
      .find('.gi-news-filter')
      .css('display', 'block');
    $(this)
      .parents('.filterSection')
      .find('.removeFilter')
      .css('display', 'block');
  });

  // eslint-disable-next-line func-names
  $('.gi-news-filter')
    .find('.abort')
    // eslint-disable-next-line func-names
    .click(function(e) {
      e.preventDefault();
      $(this)
        .parents('.gi-news-filter')
        .css('display', 'none');
    });


  $('.custSidebarHandle').click(() => {
    $('.custSidebar').slideToggle();

    const icon = $('.sidebarUp');

    icon.toggleClass('open');

    if (icon.attr('data-icon') === 'angle-down') {
      icon.attr('data-icon', 'angle-up');
    } else {
      icon.attr('data-icon', 'angle-down');
    }
  });
});
