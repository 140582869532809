import maplibregl from 'maplibre-gl';
import turfFilter from 'turf-filter';
import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
  var lang = document.documentElement.lang;

  var geourl = '/geo.json';
  var loadmore = 'Weiterlesen';
  if (lang == 'en') {
    geourl = '/en/geo.json';
    loadmore = 'Read more';
  } else if (lang == 'fr') {
    geourl = '/fr/geo.json';
    loadmore = 'Lire la suite';
  }

  var gestureOptions = {
    windowsHelpText: 'Use Ctrl + scroll to zoom the map',
    macHelpText: 'Use ⌘ + scroll to zoom the map',
    mobileHelpText: 'Use two fingers to move the map',
  }

  if (lang == 'de') {
    gestureOptions = {
      windowsHelpText: 'Halte die Taste "Strg" beim Scrollen gedrückt, um die Karte zu vergrößern',
      macHelpText: 'Halte die Taste "⌘" beim Scrollen gedrückt, um die Karte zu vergrößern',
      mobileHelpText: 'Verschieben der Karte mit zwei Fingern',
    }
  } else if (lang == 'fr') {
    gestureOptions = {
      windowsHelpText: "Vous pouvez zoomer sur la carte à l'aide de Ctrl+Molette de défilement",
      macHelpText: "Vous pouvez zoomer sur la carte à l'aide de ⌘+Molette de défilement",
      mobileHelpText: 'Utilisez deux doigt pour déplacer la carte',
    }
  }

  Array.prototype.remove = function() {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
        this.splice(ax, 1);
      }
    }
    return this;
  };

  var $features = [];

  // test for map
  var map = document.getElementById('map');
  if(map){
    $.getJSON(geourl, function(response) {
      response.data.forEach((element) => {
        if (element.length > 0) {
          element.forEach((feature) => {
            $features.push(feature);
          });
        }
      });

      var output = [];
      $features.forEach(function(item) {
        var existing = output.filter(function(v, i) {
          return (
            v.geometry.coordinates[0] == item.geometry.coordinates[0] &&
            v.geometry.coordinates[1] == item.geometry.coordinates[1]
          );
        });
        if (existing.length) {
          var existingIndex = output.indexOf(existing[0]);
          if (!output[existingIndex].properties.prop) {
            output[existingIndex].properties.prop = [];
          }
          output[existingIndex].properties.prop.push(item.properties);
        } else {
          item.value = [item.value];
          output.push(item);
        }
      });

      var places = {
        type: 'FeatureCollection',
        features: output,
      };

      var layerIDs = []; // This array will contain a list used to filter against.

      if (window.location.hostname == 'staging.invest-for-jobs.com') {
        var map = new maplibregl.Map({
          container: 'map',
          style: '/custom/style-staging.json',
          attributionControl: true,
          hash: true,
          center: [28.06, 0.09],
          zoom: 1.8,
          maxZoom: 11,
          minZoom: 1.5,
          cooperativeGestures: gestureOptions
        });
      } else if (window.location.hostname == 'localhost') {
        var map = new maplibregl.Map({
          container: 'map',
          style: '/custom/style-local.json',
          attributionControl: true,
          hash: true,
          center: [28.06, 0.09],
          zoom: 1.8,
          maxZoom: 11,
          minZoom: 1.5,
          cooperativeGestures: gestureOptions
        });
      } else {
        var map = new maplibregl.Map({
          container: 'map',
          style: '/custom/style.json',
          attributionControl: true,
          hash: true,
          center: [28.06, 0.09],
          zoom: 1.8,
          maxZoom: 11,
          minZoom: 1.5,
          cooperativeGestures: gestureOptions 
        });
      }
      

      map.addControl(new maplibregl.NavigationControl());

      map.on('load', function() {
        map.addSource('geojson-overlay', {
          type: 'geojson',
          cluster: true,
          clusterMaxZoom: 5, // Max zoom to cluster points on
          clusterRadius: 50,
          data: places,
        });

        var categoriesSelectList = [];
        var countriesSelectList = [];
        var sectionsSelectList = [];
        var withSelectList = [];
        var bySelect = null;

        var categoriesSelect = $('.categoriesSelect [type=checkbox]');
        categoriesSelect.change(function() {
          if ($(this).is(':checked')) {
            categoriesSelectList.push($(this).data('val'));
          } else {
            categoriesSelectList.remove($(this).data('val'));
          }
          updateMap();
        });

        var countriesSelect = $('.countriesSelect [type=checkbox]');
        countriesSelect.change(function() {
          if ($(this).is(':checked')) {
            countriesSelectList.push($(this).data('val'));
          } else {
            countriesSelectList.remove($(this).data('val'));
          }
          updateMap();
        });

        var sectionsSelect = $('.sectionsSelect [type=checkbox]');
        sectionsSelect.change(function() {
          if ($(this).is(':checked')) {
            sectionsSelectList.push($(this).data('val'));
          } else {
            sectionsSelectList.remove($(this).data('val'));
          }
          updateMap();
        });

        var withSelect = $('.withSelect [type=checkbox]');
        withSelect.change(function() {
          if ($(this).is(':checked')) {
            withSelectList.push($(this).data('val'));
          } else {
            withSelectList.remove($(this).data('val'));
          }
          updateMap();
        });

        var bySelectElem = document.getElementById('projectBy');
        if(bySelectElem){
          bySelect = bySelectElem.value;
          console.log('project by element: ',bySelectElem);
          console.log('project by: ',bySelect);
          updateMap();
        }

        function updateMap(params) {
          var filtered = turfFilter(places, 'location', 'makeempty');
          categoriesSelectList.forEach(function(item) {
            turfFilter(places, 'status', item).features.forEach(function(itemx) {
              filtered.features.push(itemx);
            });
          });
          countriesSelectList.forEach(function(item) {
            turfFilter(places, 'location', item).features.forEach(function(
              itemx,
            ) {
              filtered.features.push(itemx);
            });
          });
          sectionsSelectList.forEach(function(item) {
            turfFilter(places, 'sector', item).features.forEach(function(itemx) {
              filtered.features.push(itemx);
            });
          });
          withSelectList.forEach(function(item) {
            turfFilter(places, 'projectwith', item).features.forEach(function(
              itemx,
            ) {
              filtered.features.push(itemx);
            });
          });
          if(bySelect){
            console.debug(places);
            turfFilter(places, 'projectBy', bySelect).features.forEach(function(itemx) {
              filtered.features.push(itemx);
            });
          }

          if (filtered.features.length == 0) {
            map.getSource('geojson-overlay').setData(places);
          } else {
            map.getSource('geojson-overlay').setData(filtered);
          }
        }

        $('.removeFilter').on('click', function(event) {
          map.getSource('geojson-overlay').setData(places);
        });

        for (const feature of places.features) {
          const symbol = feature.properties.name;
          const layerID = `${symbol}`;

          // Add a layer for this symbol type if it hasn't been added already.
          if (!map.getLayer(layerID)) {
            map.addLayer({
              id: layerID,
              type: 'circle',
              source: 'geojson-overlay',
              filter: ['==', 'name', symbol],
            });

            layerIDs.push(layerID);
          }
        }

        map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'geojson-overlay',
          filter: ['has', 'point_count'],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            'circle-color': [
              'step',
              ['get', 'point_count'],
              '#fff',
              100,
              '#fff',
              750,
              '#fff',
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        });
        map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'geojson-overlay',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Open Sans Regular'],
            'text-size': 12,
          },
        });

        map.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'geojson-overlay',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#11b4da',
            'circle-radius': 6,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#fff',
          },
        });

        // map.fitBounds([
        //   [-33.791846, 65.576400], // southwestern corner of the bounds
        //   [34.747112, -28.993909] // northeastern corner of the bounds
        //   ]);

        // inspect a cluster on click
        map.on('click', 'clusters', (e) => {
          const features = map.queryRenderedFeatures(e.point, {
            layers: ['clusters'],
          });
          const clusterId = features[0].properties.cluster_id;
          map
            .getSource('geojson-overlay')
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom,
              });
            });
        });

        map.on('zoomend', function(e) {
          var zoom = map.getZoom();
          if (zoom > 1.99) {
            document.getElementById('map-h1').style.display = 'none';
          } else {
            document.getElementById('map-h1').style.display = 'block';
          }
        });

        map.on('click', 'unclustered-point', (e) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const title = e.features[0].properties.title;
          const url = e.features[0].properties.url;
          const summary = e.features[0].properties.summary;
          var more = false;
          if (e.features[0].properties.prop) {
            more = JSON.parse(e.features[0].properties.prop);
          }

          const truncate = (str, len) =>
            str.substring(0, (str + ' ').lastIndexOf(' ', len));

          var add = '';
          add =
            add +
            '<a href ="' +
            url +
            '">' +
            '<span style="font-size: 15px;font-weight: bold;">' +
            title +
            '</span>' +
            '<br><span style="color:black">' +
            truncate(summary, 300) +
            '...' +
            '</span><br><span class="button is-primary" style="padding: 10px;margin-top: 10px;font-size: 12px;">' +
            loadmore +
            '</span></a><br><br>';

          if (more) {
            more.forEach(function(item) {
              add =
                add +
                '<a href ="' +
                item.url +
                '">' +
                '<span style="font-size: 15px;font-weight: bold;">' +
                item.title +
                '</span>' +
                '<br><span style="color:black">' +
                truncate(item.summary, 300) +
                '...' +
                '</span><br><span class="button is-primary" style="padding: 10px;margin-top: 10px;font-size: 12px;">' +
                loadmore +
                '</span></a><br><br>';
            });
          }

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          new maplibregl.Popup()
            .setLngLat(coordinates)
            .setHTML(`${add}`)
            .addTo(map);
        });

        map.on('mouseenter', 'clusters', () => {
          map.getCanvas().style.cursor = 'default';
        });
        map.on('mouseleave', 'clusters', () => {
          map.getCanvas().style.cursor = '';
        });
        map.on('mouseenter', 'unclustered-point', () => {
          map.getCanvas().style.cursor = 'default';
        });
        map.on('mouseleave', 'unclustered-point', () => {
          map.getCanvas().style.cursor = '';
        });

        map.on('render', afterChangeComplete);

        function afterChangeComplete() {
          // still not loaded; bail out.
          if (!map.loaded()) {
            return;
          }

          // now that the map is loaded, it's safe to query the features:
          var urlParams = new URLSearchParams(window.location.search);
          var filters = urlParams.get('filter');
          if (filters) {
            var filtersArray = filters.split('-');
            filtersArray.forEach((element) => {
              if (document.getElementById(element)) {
                document.getElementById(element).click();
              }
            });
          }

          // remove this handler now that we're done.
          map.off('render', afterChangeComplete);
        }
      });
    });
  }
});
