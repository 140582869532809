import $ from 'jquery';

$(document).ready(() => {
  $('.faq-nav-link')
    .find('a')
    .click(function(e) {
      e.preventDefault();
      $('.faq-nav')
        .find('a')
        .removeClass('active');
      $(this).addClass('active');
      const index = $(this).attr('data-index');
      $(this)
        .parents('.faq')
        .find('.faq-container')
        .removeClass('active-container');
      $(this)
        .parents('.faq')
        .find('.faq-container')
        .each((i, el) => {
          if (i === Number(index)) {
            $(el).fadeIn();
          } else {
            $(el).fadeOut();
          }
        });
    });

  $('.faq-mobile')
    .find('.mob-q-container')
    .click(function() {
      if (!$(this).hasClass('active')) {
        $('.faq-qa')
          .find('.mob-ans')
          .slideUp();
        $('.faq-qa')
          .find('i')
          .removeClass('down')
          .addClass('right');
        $('.mob-q-container').removeClass('active');
      }
      $(this)
        .parents('.faq-qa')
        .find('.mob-ans')
        .slideToggle();
      $(this).toggleClass('active');
      const arrow = $(this).find('i');
      arrow.toggleClass('right down');
    });
  $('.faq-mobile')
    .find('.faq-option')
    .click(function() {
      const text = $(this).text();
      const index = $(this).attr('data-index');
      $('.faq-mobile')
        .find('.optionText')
        .text(text);
      $('.faq-mobile')
        .find('.faq-container')
        .css('display', 'none');
      $('.faq-mobile')
        .find('.faq-container')
        .eq(index)
        .css('display', 'block');
    });
});
