document.addEventListener('DOMContentLoaded', () => {
  if (window.location.href.indexOf('search') !== -1) {
    if (window.location.href.indexOf('search-query') === -1) {
      const params = new URL(document.location).searchParams;
      const query = params.get('q');

      document.getElementById('searchBox').className += ' visible';
      document.getElementById('search').placeholder = query;
    }
  }

  document.querySelector('#start-search').addEventListener('click', () => {
    document.querySelector('#search').classList.toggle('visible');
    document.querySelector('#search input').focus();
  });
});
