import $ from 'jquery';
$(document).ready(() => {
  let cookieWithdraw = 'Widerruf Ihrer Einwilligung';
  let currLanguage = document.documentElement.lang;
  if (currLanguage == 'en') {
    cookieWithdraw = 'Withdraw your consent';
  } else if (currLanguage == 'fr') {
    cookieWithdraw = 'Retrait de votre consentement';
  }

  const newTH = document.createElement('button');
  newTH.innerHTML = cookieWithdraw;
  newTH.onclick = function() {
    CookieFirst.withdrawConsent();
    this.style.display = 'none';
  };

  if (
    window.location.pathname == '/fr/declaration-de-protection' ||
    window.location.pathname == '/en/privacy-policy' ||
    window.location.pathname == '/datenschutzerklaerung'
  ) {
    var checkExist = setInterval(() => {
      if (document.getElementById('cookiefirst-policy-page')) {
        if (
          [
            'Withdraw your consent',
            'Widerruf Ihrer Einwilligung',
            'Retrait de votre consentement',
          ].indexOf(
            document
              .getElementById('cookiefirst-policy-page')
              .getElementsByTagName('button')[1].innerHTML,
          ) > -1
        ) {
          document
            .getElementById('cookiefirst-policy-page')
            .getElementsByTagName('button')[1]
            .replaceWith(newTH);
        }
        if (
          [
            'Withdraw your consent',
            'Widerruf Ihrer Einwilligung',
            'Retrait de votre consentement',
          ].indexOf(
            document
              .getElementById('cookiefirst-policy-page')
              .getElementsByTagName('button')[0].innerHTML,
          ) > -1
        ) {
          document
            .getElementById('cookiefirst-policy-page')
            .getElementsByTagName('button')[0]
            .replaceWith(newTH);
        }
        clearInterval(checkExist);
      }
    }, 500);
  }
});
