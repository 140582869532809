import $ from 'jquery';
document.addEventListener('DOMContentLoaded', () => {
  if ($(window).width() > 767) {
    var gizHeroVideo = document.getElementById('hero-video');
    if (gizHeroVideo) {
      var gizHeroVideos = JSON.parse(
        gizHeroVideo.getAttribute('videoDatArray'),
      );
      console.log(gizHeroVideos);
      var sources = gizHeroVideo.getElementsByTagName('source');
      sources[0].src = gizHeroVideos[0];
      gizHeroVideo.load();
    }
  }
});
