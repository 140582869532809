import SmoothScroll from 'smooth-scroll';
const navbarmainheight = document.getElementById('navsearchwrapper')
  .offsetHeight;

// When the user scrolls the page, execute myFunction
function scrollIndicator() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("scrollBar").style.background = `conic-gradient(#004B76 ${scrolled * 3.6}deg, #ffffff 0deg)`;
}

document.addEventListener('DOMContentLoaded', () => {
    window.onscroll = function() {scrollIndicator()};
})

new SmoothScroll('a#scrollBar', {
  offset: navbarmainheight,
  speed: 150,
  easing: 'easeOutQuad'
});