import $ from 'jquery';
import Swal from 'sweetalert2';

$(document).ready(() => {
  let currLanguage = document.documentElement.lang;
  // External Link
  let externalTitle = 'External website';
  let externalMsg = 'You are about to access an external website.';
  let externalConfirm = 'Confirm';
  let externalCancel = 'Cancel';

  // Youtube
  let ytMsg =
    "<p>When you activate the video, data will be sent to YouTube. You can find more information in the <a href='https://www.youtube.com/static?template=terms' target='_blank'>YouTube Data Processing Terms.</a></p>";
  let ytTitle = 'NOTE ON DATA PROTECTION';
  let ytConfirm = 'I agree. Play video';
  let ytDeny = 'No thanks';
  let ytClose = 'Close';
  if (currLanguage == 'de') {
    // External Link
    externalTitle = 'Externe Website';
    externalMsg = 'Sie sind dabei, eine externe Website aufzurufen.';
    externalConfirm = 'Bestätigen';
    externalCancel = 'Abbrechen';

    // Youtube
    ytMsg =
      "<p>Mit dem Aktivieren des Videos werden Daten an YouTube übermittelt. Mehr Informationen dazu finden Sie in der <a href='https://www.youtube.com/static?template=terms' target='_blank'>Datenschutzerklärung von YouTube.</a></p>";
    ytTitle = 'HINWEIS ZUM DATENSCHUTZ';
    ytConfirm = 'Zustimmen und abspielen';
    ytDeny = 'Nicht zustimmen';
    ytClose = 'Schließen';
  }
  if (currLanguage == 'fr') {
    // External Link
    externalTitle = 'Site externe';
    externalMsg = "Vous êtes sur le point d'accéder à un site Internet externe.";
    externalConfirm = 'Confirmer';
    externalCancel = 'Annuler';

    // Youtube
    ytMsg =
      "<p>En activant cette vidéo, vous communiquez des données à YouTube. Pour en savoir plus à ce propos, veuillez consulter la <a href='https://www.youtube.com/static?template=terms' target='_blank'>déclaration de YouTube relative à la protection des données.</a></p>";
    ytTitle = 'INFORMATION SUR LA PROTECTION DES DONNÉES';
    ytConfirm = 'Accepter et lancer la vidéo';
    ytDeny = 'Refuser';
    ytClose = 'Proche';
  }

  // eslint-disable-next-line func-names
  $('a')
    .filter(function() {
      // eslint-disable-next-line no-restricted-globals
      return this.hostname && this.hostname !== location.hostname;
    })
    .click((e) => {
      e.preventDefault();
      Swal.fire({
        title: externalTitle,
        html: externalMsg,
        showCancelButton: true,
        confirmButtonText: externalConfirm,
        cancelButtonText: externalCancel,
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button is-primary',
          actions: 'swal-action'
        },
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          $(this).hide();
          window.open(e.currentTarget.href, '_blank').focus();
          // window.location.href = e.target.href;
        } else if (result.isDenied) {
          return false;
          // console.log('second')
        }
      });
    });

  $('.dummy-iframe').click(function(e) {
    if ($(e.target).is('a')) return;
    Swal.fire({
      title: ytTitle,
      html: ytMsg,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: ytConfirm,
      denyButtonText: ytDeny,
      customClass: {
        confirmButton: 'button is-primary',
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        $(this).hide();
        $(this)
          .next('.youtube-iframe')
          .attr(
            'src',
            $(this)
              .next('.youtube-iframe')
              .attr('data-src'),
          );
      } else if (result.isDenied) {
        // console.log('second')
      }
    });
  });
  let ytClicked = false;
  $('.yt-swal').click(function(e) {
    if ($(e.target).is('a')) return;
    console.log(this.dataset.src);
    console.log(this.dataset.related);
    let variable =
      '<iframe width="950" height="382" src="https://www.youtube-nocookie.com/embed/' +
      this.dataset.src +
      '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
    const relatedData = this.dataset.related;
    if (relatedData) {
      variable = variable + relatedData;
    }
    if (ytClicked == true) {
      Swal.fire({
        html: variable,
        showCloseButton: true,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: false,
        denyButtonText: ytClose,
        customClass: {
          container: 'swal-yt-container',
          confirmButton: 'swal-yt-confirm-button',
          cancelButton: 'swal-yt-cancel-button-class',
        },
      });
    } else {
      Swal.fire({
        title: ytTitle,
        html: ytMsg,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: ytConfirm,
        denyButtonText: ytDeny,
        customClass: {
          confirmButton: 'button is-primary',
        },
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          ytClicked = true;
          Swal.fire({
            html: variable,
            showDenyButton: false,
            showCloseButton: true,
            showCancelButton: false,
            confirmButtonText: false,
            denyButtonText: ytClose,
            customClass: {
              container: 'swal-yt-container',
              confirmButton: 'swal-yt-confirm-button',
              cancelButton: 'swal-yt-cancel-button-class',
            },
          });
        } else if (result.isDenied) {
          // console.log('second')
        }
      });
    }
  });
  let divList = $('.gi-news-box:gt(0)');
  let divListFirst = $('.gi-news-box:first');
  $('.filterOptionDateNew').click(function() {
    divList.sort(function(a, b) {
      return $(b).data('date') - $(a).data('date');
    });
    $('#media-grid').html(divList);
    $('#media-grid').prepend(divListFirst);
  });
  $('.filterOptionDateOld').click(function() {
    divList.sort(function(a, b) {
      return $(a).data('date') - $(b).data('date');
    });
    $('#media-grid').html(divList);
    $('#media-grid').prepend(divListFirst);
  });
});
