import flatpickr from "flatpickr"
import { German } from "flatpickr/dist/l10n/de.js"
import { French } from "flatpickr/dist/l10n/fr.js"

document.addEventListener('DOMContentLoaded', () => {
   
    document.querySelectorAll("*[data-datepicker]").forEach( element => {
        var locale = element.getAttribute("data-datepicker-locale");
        if (locale == 'de') {
            flatpickr.localize(German);
        } else if (locale == 'fr') {
            flatpickr.localize(French);
        }

        var options = {    
            disableMobile: !0,
            allowInput: !0,
            dateFormat: element.getAttribute("data-datepicker-format"),
            enableTime: null !== element.getAttribute("data-datepicker-enabletime"),
            noCalendar: null === element.getAttribute("data-datepicker-enabledate"),
            time_24hr: null !== element.getAttribute("data-datepicker-clock_24h"),
            minDate: element.getAttribute("data-datepicker-min-date"),
            maxDate: element.getAttribute("data-datepicker-max-date"),
            minuteIncrement: 1,
            hourIncrement: 1,
            static: null !== element.getAttribute("data-datepicker-static")
        };
        flatpickr('*[data-datepicker]', options);
    })
});

