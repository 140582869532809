import stickybits from 'stickybits';
import 'waypoints/lib/noframework.waypoints.min';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelectorAll('#in-page-nav').length >= 1) {
    const navbarmainheight = document.getElementById('navsearchwrapper')
      .offsetHeight;
    const inpagenavheight = document.getElementById('in-page-nav-container')
      .offsetHeight;

    if (
      navigator.appName === 'Microsoft Internet Explorer' ||
      !!(
        navigator.userAgent.match(/Trident/) ||
        navigator.userAgent.match(/rv:11/)
      )
    ) {
      stickybits('.sticky-top', {
        stickyBitStickyOffset: navbarmainheight,
        useFixed: true,
      });
      document.getElementById('in-page-nav-container').style.top = 0;
    } else {
      stickybits('.sticky-top', {
        stickyBitStickyOffset: navbarmainheight,
        useStickyClasses: true,
      });
    }

    const waypoints = document.querySelectorAll('#in-page-nav a');
    waypoints.forEach((element) => {
      const cleanWaypoint = element.getAttribute('href').replace('#', '');
      new Waypoint({
        element: document.getElementById(cleanWaypoint),
        handler() {
          const curr = cleanWaypoint;
          if (document.querySelectorAll('#in-page-nav .active').length >= 1) {
            document
              .querySelector('#in-page-nav .active')
              .classList.remove('active');
          }
          document
            .querySelector(`#in-page-nav a[href*="#${curr}"]`)
            .classList.add('active');
        },
        offset: navbarmainheight + inpagenavheight,
      });
    });
  }
});
