/* eslint-disable space-before-function-paren */
import SmoothScroll from 'smooth-scroll';
import LazyLoad from 'vanilla-lazyload';
import $ from 'jquery';
import './modules/nav';
import './modules/search';
import './modules/in-page-nav';
import './modules/cookie-opt-in';
import './modules/glide';
import './modules/accordion';
import './modules/news-filter';
import './modules/pagination';
import './modules/tables';
import './modules/faq';
import './modules/gsap';
import './modules/splide';
import './modules/pop';
import './modules/noopener';
import './modules/filters';
import './modules/navbar';
import './modules/object-fit-images';
import './modules/video-loop';
import './modules/map';
import './modules/flatpicker';
import './modules/scroll-indicator';

require('./head.js');
